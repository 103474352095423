<template>
    <div class="row">
        <div v-bind:class="firstColumn">
            <div class="form-group no-horizontal-margin">
                <label for="country_id">Country</label>
                <select id="country_id" name="country_id" class="form-control" v-model="currentCountryId">
                    <option disabled value="">Select your country</option>
                    <option v-for="country in countries" v-bind:value="country.id">{{ country.name }}</option>
                </select>
                <span class="help-block has-error" v-if="errors.length > 0">
                    <strong>{{ errors }}</strong>
                </span>
            </div>
        </div>
        <div v-bind:class="secondColumn">
            <div class="form-group no-horizontal-margin">
                <label for="region_id">Region</label>
                <select id="region_id" name="region_id" class="form-control" v-model="currentRegionId"
                        v-bind:disabled="currentRegions.length === 0">
                    <option disabled value="">Select your region</option>
                    <option v-for="region in currentRegions" v-bind:value="region.id">{{ region.name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    select:disabled {
        opacity: 0.5;
    }
</style>
<script>
    import RegionsMixin from "./RegionsMixin";

    export default {
        mixins: [RegionsMixin]
    }
</script>