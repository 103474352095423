class Utils {

	constructor() {
		this.isTouchDevice = (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
		if(this.isTouchDevice) jQuery('body').addClass('touch-device');
		// Initial mobile viewport, does not auto updates
		this.isMobileViewport = jQuery(window).width() < 768;
	}

	// Debounce timeout
	debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
}

export default new Utils;