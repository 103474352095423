<script>
    import * as _ from 'lodash';

    export default {
        props:    ['regions', 'selectedRegion', 'errors'],
        data() {
            console.log(`[Regions] - data() :: `);

            let firstColumn = {
                'col-md-6':  true,
                'has-error': this.errors.length > 0
            };

            let secondColumn = {
                'col-md-6':        true,
                'has-error':       this.errors.length > 0

            };

            if (this.selectedRegion) {
                return {
                    currentCountryId : this.selectedRegion.parent_id ? _.find(this.regions, {'id': this.selectedRegion.parent_id}).id : _.find(this.regions, {'id': this.selectedRegion.id}).id,
                    currentRegionId:  _.find(this.regions, {'id': this.selectedRegion.id}).id,
                    firstColumn:      firstColumn,
                    secondColumn:     secondColumn,
                };
            }

            return {
                currentCountryId: '',
                currentRegionId: '',
                firstColumn:      firstColumn,
                secondColumn:     secondColumn,
            }
        },
        computed: {
            countries() {
                console.log(`[Regions] - countries() ::`, this.regions);
                return _.filter(this.regions, {'parent_id': null});
            },
            currentRegions() {
                console.log(`[Regions] - currentRegions() ::`, this.regions);
                return _.filter(this.regions, {'parent_id': this.currentCountryId});
            },
        },
        watch: {
            currentRegions() {
                this.$nextTick(() => {
                    let ms = $('.mobiscroll-sm');
                    if(ms.length) {
                        ms.mobiscroll().select({
                            theme: 'mobiscroll',
                            lang: 'en',
                            display: 'inline',
                            minWidth: 200
                        });
                    }
                });
            },

            currentCountryId() {
                this.setAddressInputProps();
            },
        },
        mounted() {
            console.log(`[Regions] - mounted() ::`);

            this.setAddressInputProps();
        },
        methods:  {
            setAddressInputProps() {
                console.log(`[Regions] - setAddressInputProps() ::`, this.currentCountryId);

                if (this.currentCountryId && this.currentCountryId.length > 0) {
                    $('#address-input').prop('disabled', false);

                    let currentCountry = _.find(this.regions, {'id': this.currentCountryId})
                    let countryCode = currentCountry.country_code;
                    window.googleAutocompleteCountry = countryCode;

                    if (window.googleAutocompleteObjects) {
                        window.googleAutocompleteObjects.forEach(object => {
                            object.setComponentRestrictions({'country': countryCode});
                        });
                    }
                } else {
                    $('#address-input').prop('disabled', true);
                }
            }
        }
    }
</script>