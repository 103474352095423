import Utils from './utils';

// Login page only related code
class Login {

	constructor() {
		this.$body = $('body');

		$(document).ready(() => {
			setTimeout(() => {
				this.init();
			}, 600)
		});
	}

	init() {
		if(! this.$body.hasClass('login-page')) {
			return;
		}
		$('.login-container').removeClass('hidden');
		this.animate();
	}

	animate() {
		var timeline = new TimelineMax();
		timeline.append(TweenMax.staggerFrom($('.login-container .animated'), 0.5, { opacity: 0, x: -200 }, 0.2));
		timeline.append(TweenMax.staggerFrom($('.login-container .dot'), 0.3, { scale: 0.8 }, 0.1 ));
	}
}

export default new Login();