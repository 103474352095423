import Utils from './utils';

// Profile page only related code
class Profile {

	constructor() {
		this.$body = $('body');

		$(document).ready(() => {
			setTimeout(() => {
				this.init();
			}, 600)
		});
	}

	init() {
		if(!this.$body.hasClass('profile-page')) {
			return;
		}

		$('.profile-container').removeClass('hidden');
		this.animate();

		// trigger tabs from outside
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		    var target = this.href.split('#');
		    $('.nav a').filter('[href="#'+target[1]+'"]').tab('show');
		})

		// household icons
		$('.household-icon, .household-label').click((e) => {
			e.preventDefault();
			var $el = $(e.target).parent('.household');
			var value = $el.attr('data-id');
			$('.select').addClass('active');
			$el.addClass('active').siblings().removeClass('active');
			console.log('household icons: ', value);
			$('#household_id').val(value);
		});

		$('.mobiscroll-sm').mobiscroll().select({
			theme: 'mobiscroll',
        	lang: 'en',
        	display: 'inline',
        	minWidth: 200
		});

		$('.mobiscroll-lg').mobiscroll().select({
			theme: 'mobiscroll',
        	lang: 'en',
        	display: 'inline',
        	minWidth: 400   
		});

		$('.mobiscroll-container')
			.on('mouseenter', function(e) {
				$(this).find('div[tabindex="0"]').focus();
			})
			.on('mouseleave', function(e) {
				$(this).find('div[tabindex="0"]').blur();
			});

		$('.mobiscroll-container').find('select').change(function() {
			$(this).parent('.mobiscroll-container').addClass('changed');
		});

		$('.btn-logout').click((e) => {
			e.preventDefault();
			sweetAlert({
				title: "Thanks for joining Yabble!",
				text: "We’re going to have lots of fun collaborating together. We’ll be in touch soon with official Yabble business.",
				type: "success",
				showConfirmButton: true,
				confirmButtonText: "Close"
			}, (isConfirm) => {
		    	window.location = window.location.origin + '/logout';
			});
		});
		// focus on tabs with errors
		if ($('.nav-tabs > li.has-error').length) {
			$('.nav-tabs > li.has-error:eq(0) a').click();
		}
	}

	animate() {
		var timeline = new TimelineMax();
		timeline.append(TweenMax.staggerFrom($('.profile-container .animated'), 0.5, { opacity: 0, x: -200 }, 0.2));
		timeline.append(TweenMax.staggerFrom($('.profile-container .dot'), 0.3, { scale: 0.8 }, 0.1 ));
	}
}

export default new Profile();