<template>
    <div>
        <label for="country_id">Country</label>
        <select id="country_id" name="country_id" class="form-control" v-model="currentCountryId" required="required">
            <option disabled value="">Select your country</option>
            <option v-for="country in countries" v-bind:value="country.id">{{ country.name }}</option>
        </select>
        <span style="color: red;" v-if="errors">{{ errors }}</span>

        <label for="region_id">Region</label>
        <select id="region_id" name="region_id" class="form-control" v-model="currentRegionId"
                v-bind:required="currentRegions.length !== 0"
                v-bind:disabled="currentRegions.length === 0">
            <option disabled value="">Select your region</option>
            <option v-for="region in currentRegions" v-bind:value="region.id">{{ region.name }}</option>
        </select>
    </div>
</template>
<style lang="scss" scoped>
    select:disabled {
        opacity: 0.5;
    }
</style>
<script>
    import RegionsMixin from "./RegionsMixin";

    export default {
        mixins: [RegionsMixin],
    }
</script>