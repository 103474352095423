import Utils from './utils';

// Register page only related code
class Register {

	constructor() {
		console.log('[Register] - constructor');
		this.$body = $('body');
		this.$genderSelect = $('select#gender');

		$(document).ready(() => {
			setTimeout(() => {
				this.init();
			}, 600)
		});
	}

	init() {
		console.log('[Register] - init');
		if (! this.$body.hasClass('register-page')) {
			return;
		}


		$('.register-container').removeClass('hidden');
		
		if (! $('form.has-errors').length) {
			this.animate();
		}

		$('.show-register-form').click((e) => {
			e.preventDefault();
			var timeline = new TimelineMax();
			timeline.append(TweenMax.staggerTo($('.register-text, .register-buttons'), 0.5, { opacity: 0, y: -120, ease: Power4.easeOut }));
			timeline.append(TweenMax.staggerFrom($('.register-form'), 0.5, { opacity: 0, y: 120, height: 0, ease: Back.easeOut }));
			setTimeout(() => {
				$('.register-form').removeClass('hidden');
				$('.register-text, .register-buttons').addClass('hidden');
			}, 500)
		});

		this.selectGender(this.$genderSelect.val());

		console.log('[Register] - init() :: Attaching change event handler to ', this.$genderSelect);

		this.$genderSelect.on('change', () => {
			this.selectGender(this.$genderSelect.val());
		});

		$('.select-icons i').click((e) => {
			e.preventDefault();
			var value = $(e.target).attr('data-value');
			this.$genderSelect.val(value);
			this.selectGender(value);
		});

		this.validatePhoneNumber();
		this.validateEmail();
		this.validatePassword();
	}

	animate() {
		var timeline = new TimelineMax();
		timeline.append(TweenMax.staggerFrom($('.register-container .animated'), 0.5, { opacity: 0, x: -200 }, 0.3));
		timeline.append(TweenMax.staggerFrom($('.register-container .dot'), 0.3, { scale: 0.8, ease: Elastic.easeOut.config(2.5, 1) }, 0.15 ));
	}

	selectGender(value) {
		console.log('[Register] - selectGender', value);
		switch (value) {
			case 'male':
				$('.select-icons i[data-value="male"]').addClass('icon-male-selected');
				$('.select-icons i[data-value="female"]').removeClass('icon-female-selected');
				$('.select-icons i[data-value="gender-diverse"]').removeClass('icon-gender-diverse-selected');
				this.$genderSelect.addClass('valid');
				break;
			case 'female':
				$('.select-icons i[data-value="male"]').removeClass('icon-male-selected');
				$('.select-icons i[data-value="female"]').addClass('icon-female-selected');
				$('.select-icons i[data-value="gender-diverse"]').removeClass('icon-gender-diverse-selected');
				this.$genderSelect.addClass('valid');
				break;
			case 'gender-diverse':
				$('.select-icons i[data-value="female"]').removeClass('icon-female-selected');
				$('.select-icons i[data-value="male"]').removeClass('icon-male-selected');
				$('.select-icons i[data-value="gender-diverse"]').addClass('icon-gender-diverse-selected');
				this.$genderSelect.addClass('valid');
				break;
			default:
				$('.select-icons i[data-value="male"]').removeClass('icon-male-selected');
				$('.select-icons i[data-value="female"]').removeClass('icon-female-selected');
				$('.select-icons i[data-value="gender-diverse"]').removeClass('icon-gender-diverse-selected');
				this.$genderSelect.removeClass('valid');
		}
	}

	validatePhoneNumber() {
		var phoneNumber = document.getElementById("phone_number");

		if (phoneNumber === null) {
			return;
		}

		function validate() {
			var format = /^\d+$/;
		  	if (! phoneNumber.value.match(format)) {
		    	phoneNumber.setCustomValidity("Phone number must be a number");
		  	} else {
		    	phoneNumber.setCustomValidity('');
		  	}
		}

		phoneNumber.onkeyup = validate;
	}

	validateEmail() {
		var email = document.getElementById("email"),
		  	emailConfirmation = document.getElementById("email_confirmation");

		if (email === null || emailConfirmation === null) {
			return;
		}

		function validate() {
			var emailFormat  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				emailMessage = "Please enter valid email address"
		  	if (! email.value.match(emailFormat)) {
		    	email.setCustomValidity(emailMessage);
		  	} else {
		    	email.setCustomValidity('');
		  	}
		}

		function validateConfirmation() {
		  	if (email.value != emailConfirmation.value) {
		    	emailConfirmation.setCustomValidity("Email confirmation doesn't match");
		  	} else {
		    	emailConfirmation.setCustomValidity('');
		  	}
		}

		email.onkeyup = validate;
		email.onchange = validateConfirmation;
		emailConfirmation.onkeyup = validateConfirmation;
	}

	validatePassword() {
		var password = document.getElementById("password"),
		  	passwordConfirmation = document.getElementById("password_confirmation");

		if (password === null || passwordConfirmation === null) {
			return;
		}

		function validate() {
			var passwordFormat 	= /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
				passwordMessage = "Password must be at least 8 characters and include 1 number and 1 capital letter";

		  	if (! password.value.match(passwordFormat)) {
		    	password.setCustomValidity(passwordMessage);
		  	} else {
		    	password.setCustomValidity('');
		  	}
		}

		function validateConfirmation() {
		  	if (password.value != passwordConfirmation.value) {
		    	passwordConfirmation.setCustomValidity("Password confirmation doesn't match");
		  	} else {
		    	passwordConfirmation.setCustomValidity('');
		  	}
		}

		password.onkeyup = validate;
		password.onchange = validateConfirmation;
		passwordConfirmation.onkeyup = validateConfirmation;
	}
}

export default new Register();