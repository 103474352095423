require('./bootstrap');

Vue.config.errorHandler = function(err, vm, info) {
    console.log(err);
};

Vue.component('regions', require('./components/Regions.vue').default);
Vue.component('regions-for-register', require('./components/RegionsForRegister.vue').default);

const app = new Vue({
    el: '#app',
    created() {
        console.log('[app] - created()');
    }
});

$(document).ready(function () {
    switch (
        ($(".toggle-menu").click(function () {
            $(".hamburger").toggleClass("is-active"),
                $(".menu-mobile").toggleClass("is-active"),
                $("body").toggleClass("no-scroll");
        }),
            setTimeout(function () {
                $(".page-loader").addClass("hidden");
            }, 600),
            window.location.hash.substr(1))
        ) {
        case "terms-and-conditions":
            $("#termsModalButton").click();
            break;
        case "privacy":
            $("#privacyModalButton").click();
    }
});

setTimeout(() => {
    var hidePopUp = localStorage.getItem('HidePopUp');
    if (!hidePopUp) {
        $('.popup').addClass('is-active');
    }
    $('.btn-popup').click(function () {
        localStorage.setItem('HidePopUp', true);
        $('.popup').removeClass('is-active');
    })
}, 3000);
