// Home page only related code
class Home {
    constructor() {
        this.$body = $('body')

        $(document).ready(() => {
            setTimeout(() => {
                this.init()
            }, 600)
        })
    }

    init() {
        if (!this.$body.hasClass('home-page')) {
            return
        }

        $('.home-container').removeClass('hidden')

        this.initParallax()
        this.initFullpage()
        this.initCustomersCarousel()
        this.animateHero()
        this.openModals()
    }

    initParallax() {
        var scenes = document.getElementsByClassName('home-scene')
        for (var i = 0; i < scenes.length; i++) {
            let parallax = new Parallax(scenes[i])
        }
    }

    initFullpage() {
        $('#home-fullpage').fullpage({
            normalScrollElements: '.modal',
            responsiveWidth: 992,
            responsiveHeight: 0,
            scrollingSpeed: 1400,

            // hacky way to fix the responsive offset bug, without that resizing screen to mobile size
            // only when other than the first section active will give a broken look
            afterResponsive: isResponsive => {
                console.debug('Home :: fullpage :: afterResponsive()', isResponsive)

                if (isResponsive) {
                    $('#home-fullpage').css('transform', 'none')
                }
            },
            afterLoad: (anchorLink, index, slideAnchor, slideIndex) => {
                var tl = new TimelineMax()
                tl.staggerTo($(`.fp-section:eq(${index - 1}) .dots .dot`), 0.12, {scale: 0.85, yoyo: true, repeat: 1}, 0.15)
            }
        })
        $('.btn-next-section').click(e => {
            e.preventDefault()
            $.fn.fullpage.moveSectionDown()
        })
        $('.btn-back-to-top, .logo, .logo-mobile').click(e => {
            e.preventDefault()
            $.fn.fullpage.moveTo(1)
        })
        if (window.location.hash === '#is-yabble') {
            $.fn.fullpage.silentMoveTo(2)
        }
        this.$body.on('show.bs.modal', function(e) {
            $.fn.fullpage.setAllowScrolling(false)
            $.fn.fullpage.setKeyboardScrolling(false)
        })
        this.$body.on('hide.bs.modal', function(e) {
            $.fn.fullpage.setAllowScrolling(true)
            $.fn.fullpage.setKeyboardScrolling(true)
        })
    }

    initCustomersCarousel() {
        $('.customers-carousel-1').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            dots: false,
            draggable: false,
            pauseOnFocus: false,
            infinite: true
        })
        $('.customers-carousel-2').slick({
            slidesToShow: 2,
            slidesToScroll: -1,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: false,
            dots: false,
            draggable: false,
            pauseOnFocus: false,
            infinite: true
        })
        $('.customers-carousel-3').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: false,
            dots: false,
            draggable: false,
            pauseOnFocus: false,
            infinite: true
        })
    }

    animateHero() {
        $('.section-1 .inner').removeClass('hidden')

        var timeline = new TimelineMax({delay: 1})
            .add(TweenMax.fromTo($('.hero-logo'), 0.6, {opacity: 0, y: 300}, {opacity: 1, y: 0}), 0)
            .add(TweenMax.staggerFrom($('.hero-logo .dot'), 0.8, {scale: 0.3, opacity: 0, ease: Elastic.easeOut.config(2, 1)}, 0.3))
            .add(TweenMax.fromTo($('.hero-text'), 0.6, {opacity: 0, y: 300}, {opacity: 1, y: 0}), 0)
            .add(TweenMax.fromTo($('.hero-buttons .btn-next-section'), 0.6, {opacity: 0, x: -200}, {opacity: 1, x: 0}), 0.6)
            .add(TweenMax.fromTo($('.hero-buttons .btn-business-users'), 0.6, {opacity: 0, x: 200}, {opacity: 1, x: 0}), 0.6)
            .add(TweenMax.fromTo($('.hero-buttons .btn-join'), 0.6, {opacity: 0 }, {opacity: 1}), 0.9)
            .add(TweenMax.fromTo($('.contact-btn'), 0.4, {opacity: 0, x: -200}, {opacity: 1, x: 0}), 1)
            .add(TweenMax.fromTo($('.register-btn'), 0.4, {opacity: 0, x: 200}, {opacity: 1, x: 0}), 1)
            .add(TweenMax.fromTo($('.home-scroller'), 0.3, {opacity: 0, y: 100}, {opacity: 1, y: 0}), 1.4)
    }

    openModals() {
        switch (window.location.hash) {
            case '#privacy':
                $('#privacy').modal('show')
                break
            case '#terms':
                $('#terms-and-conditions').modal('show')
                break
        }
    }
}

export default new Home()
