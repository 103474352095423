window._ = require('lodash');
window.$ = window.jQuery = require('jquery');

window.Vue   = require('vue');
window.axios = require('axios');
window.axios.defaults.headers.common = {
    'X-CSRF-TOKEN':     window.Laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
};
Vue.prototype.$http = axios;

require('bootstrap-sass');
require('fullpage.js');
require('gsap');
require('sweetalert');
require('slick-carousel');
require('../../../node_modules/parallax-js/deploy/parallax.js');
require('./modules/utils');
require('./modules/contact');
require('./modules/register');
require('./modules/login');
require('./modules/profile');
require('./modules/home');
require('./modules/mobiscroll.custom-3.0.0.min');
require('./google-autocomplete');
