// google autocomplete
function initialize() {
    let options = {
        types: ['address'],
    }
    let inputs = document.getElementsByClassName('google-autocomplete');
    window.googleAutocompleteObjects = [];
    for (i = 0; i < inputs.length; i++) {
        let input = inputs[i];
        let autocomplete = new google.maps.places.Autocomplete(input, options);
        window.googleAutocompleteObjects.push(autocomplete);
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            input.classList.add('valid')
            let place = autocomplete.getPlace();
            let location = JSON.stringify(_.pick(place, ['address_components', 'formatted_address', 'geometry', 'place_id', 'types']));
            $locationInput = $('.google-autocomplete-location');
            if ($locationInput.length) {
                $locationInput.val(location);
            }
        })

        if (window.googleAutocompleteCountry) {
            autocomplete.setComponentRestrictions({'country': window.googleAutocompleteCountry});
        }
    }
}
google.maps.event.addDomListener(window, 'load', initialize)
