import Utils from './utils';

// Contact page only related code
class Contact {

	constructor() {
		this.$body = $('body');

		$(document).ready(() => {
			setTimeout(() => {
				this.init();
			}, 600)
		});
	}

	init() {
		if(! this.$body.hasClass('contact-page')) {
			return;
		}
		$('.contact-container').removeClass('hidden');
		this.animate();
	}

	animate() {
		var timeline = new TimelineMax();
		timeline.append(TweenMax.staggerFrom($('.contact-container .animated'), 0.5, { opacity: 0, x: -200 }, 0.25));
		timeline.append(TweenMax.staggerFrom($('.contact-container .dot'), 0.3, { scale: 0.75 }, 0.15 ));
	}
}

export default new Contact();